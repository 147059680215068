<template>
<div>
    <multiselect v-if="isMultiple == true" v-model="DisplayValue" :options="options" :multiple="true" placeholder="Select Case" track-by="dropDownName" :clear-on-select="false" :show-labels="false" label="dropDownName" :preselect-first="true" v-bind:class="($i18n.locale == 'en' || isLeftToRight()) ? 'text-left ' : 'arabicLanguage '">
        <a slot="noResult" class="btn btn-primary " v-on:click="AddBrand('Add')" v-if="isValid('CanAddProduct')">Select Variant</a><br />

    </multiselect>
    <multiselect v-else v-model="DisplayValue" :options="options" :multiple="false" placeholder="Select Case" track-by="dropDownName" :clear-on-select="false" :show-labels="false" label="dropDownName" :preselect-first="true" v-bind:class="($i18n.locale == 'en' || isLeftToRight()) ? 'text-left ' : 'arabicLanguage '">
        <a slot="noResult" class="btn btn-primary " v-on:click="AddBrand('Add')" v-if="isValid('CanAddProduct')">Select Variant</a><br />

    </multiselect>

  
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import clickMixin from '@/Mixins/clickMixin'
import 'vue-loading-overlay/dist/vue-loading.css';
import {
    requiredIf,
    maxLength
} from "vuelidate/lib/validators"
export default {
    mixins: [clickMixin],
    name: 'branddropdown',
    props: ["values", "isMultiple"],

    components: {
        Multiselect,
    },
    data: function () {
        return {
            arabic: '',
            english: '',
            options: [],
            value: '',
            show: false,
            type: '',
            productMaster: {
                id: '00000000-0000-0000-0000-000000000000',
                code: '',
                name: '',
                nameArabic: '',
                description: '',
                isActive: true
            },
            render: 0,
            loading: false,
        }
    },
    validations: {
        productMaster: {
            name: {
                maxLength: maxLength(50)
            },
            nameArabic: {
                required: requiredIf((x) => {
                    if (x.name == '' || x.name == null)
                        return true;
                    return false;
                }),
                maxLength: maxLength(50)
            },
            code: {
                maxLength: maxLength(30)
            },
            description: {
                maxLength: maxLength(200)
            }
        }
    },
    methods: {
        EmptyRecord: function () {

            this.DisplayValue = '';

        },
        getData: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }

            let url =  "/Product/GetProductInformation?status=" + root.status + "&isDropdown=true" + '&isRaw=' + true + "&isFifo=" + false + '&isService=true' ;



            root.options = [];
            this.$https.get(url, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then(function (response) {

                if (response.data != null) {
                    console.log('sdadasdasdasdadadasd')
                    console.log(response.data)
                    response.data.results.products.forEach(function (result) {
                        root.options.push({
                            id: result.id,
                            dropDownName: result.code + ' ' + result.englishName,
                            name: result.englishName
                        })
                    })
                }
            }).then(function () {
                root.value = root.options.find(function (x) {
                    return x.id == root.values;
                })
            });
        },
        AddBrand: function (type) {
            this.$v.$reset();
            this.GetAutoCodeGenerator();
            this.productMaster = {
                id: '00000000-0000-0000-0000-000000000000',
                code: '',
                name: '',
                nameArabic: '',
                description: '',
                isActive: true
            }

            this.show = !this.show;
            this.type = type;
        },
        close: function () {
            this.show = false;
        },
        GetAutoCodeGenerator: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            this.$https.get('/Product/ProductMasterCode', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then(function (response) {

                if (response.data != null) {
                    root.productMaster.code = response.data;
                    root.render++;
                }
            });
        },
        SaveBrand: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            this.$https.post('/Product/SaveProductMaster', this.productMaster, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(function (response) {

                    if (response.data.isSuccess == true) {
                        if (root.type != "Edit") {
                            root.getData();
                            //root.$store.state.productMasterList.push({
                            //    id: response.data.productMaster.id,
                            //    name: response.data.productMaster.name,
                            //    nameArabic: response.data.productMaster.nameArabic,
                            //    description: response.data.productMaster.description,
                            //    code: response.data.productMaster.code,
                            //})
                            //root.options.push({
                            //    id: response.data.productMaster.id,
                            //    dropDownName: root.$i18n.locale == 'en' ? (response.data.productMaster.name != '' ? response.data.productMaster.code + ' ' + response.data.productMaster.name : response.data.productMaster.code + ' ' + response.data.productMaster.nameArabic) : (response.data.productMaster.nameArabic != '' ? response.data.productMaster.code + ' ' + response.data.productMaster.nameArabic : response.data.productMaster.code + ' ' + response.data.productMaster.name),
                            //    name: response.data.productMaster.name,
                            //    nameArabic: response.data.productMaster.nameArabic,
                            //    description: response.data.productMaster.description,
                            //    code: response.data.productMaster.code,
                            //})
                            root.$swal({
                                icon: 'success',
                                title: 'Saved Successfully!',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                            root.show = false;
                        } else {
                            root.getData();

                            //var data = root.$store.state.productMasterList.find(function (x) {
                            //    return x.id == response.data.productMaster.id;
                            //});
                            //data.id = response.data.productMaster.id;
                            //data.name = response.data.productMaster.name;
                            //data.nameArabic = response.data.productMaster.nameArabic;
                            //data.description = response.data.productMaster.description;
                            //data.code = response.data.productMaster.code;

                            root.show = false;
                        }
                    } else {
                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text: "Your Product Master Name  Already Exist!",
                            type: 'error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }
                })
                .catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Something Went Wrong!' : 'هل هناك خطب ما!',
                        text: error.response.data,

                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });

                    root.loading = false
                })
                .finally(() => root.loading = false);
        }
    },
    computed: {
        DisplayValue: {
            get: function () {

                if (this.value != "" || this.value != undefined) {
                    return this.value;
                }
                return this.values;
            },
            set: function (value) {
                this.value = value;
                if (value == null || value == undefined) {
                    this.$emit('input', value);

                } else {
                    if (this.isMultiple == true) {
                        this.$emit('input', value);
                    } else {
                        this.$emit('input', value);
                    }

                }
            }
        }
    },
    mounted: function () {
        this.english = 'true';
        this.arabic = localStorage.getItem('Arabic');
        this.getData();
    },
}
</script>
